import React from 'react';
import { Text, Divider } from 'wix-ui-tpa/cssVars';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { useSettingsParams } from '../../Hooks/useSettingsParams';
import { classes, st } from './SectionHeader.st.css';
import { DataHooks } from './constants';

export type SectionHeaderProps = {
  title: string;
  suffix?: React.ReactNode;
};

const SectionHeader: React.FC<sectionheaderprops> = ({ title, suffix }) => {
  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const settingsParams = useSettingsParams();

  return (
    <>
      <div className="{st(classes.root," {="" isMobile,="" isRTL,="" alignment:="" settings.get(settingsParams.textAlignment),="" withSuffix:="" !!suffix,="" })}="">
        <text data-hook="{DataHooks.Title}" className="{classes.title}" tagName="{settings.get(settingsParams.sectionHeaderHtmlTag)}">
          {标题｝
        </text>
        {suffix}
      </div>
      <divider className="{classes.divider}" data-hook="{DataHooks.Divider}"></divider>
    </>
  );
};

export default SectionHeader;
</sectionheaderprops>